import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectActions } from '@expocab/entities/project';
import { ROUTE_PARAMS_TOKENS } from '@expocab/shared/app-config';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { NotificationService } from '@swagger/services/notification.service';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';

import { NotificationActions } from './notification.actions';

@Injectable()
export class NotificationEffects {
  private readonly ROUTE_PARAMS_TOKENS = ROUTE_PARAMS_TOKENS;

  changeNotificationsCounterInProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.markNotificationsAsWatchedSuccess),
      concatMap(() => of(ProjectActions.decreaseNotificationsCounter())),
    );
  });

  loadNotificationDetailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.loadNotificationDetailed),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.NOTIFICATION_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.notificationService.notificationRead(Number(id)).pipe(
          map((notification) =>
            NotificationActions.loadNotificationDetailedSuccess({
              notification,
            }),
          ),
          catchError((error: unknown) =>
            of(
              NotificationActions.loadNotificationDetailedFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while loading detailed notification',
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.loadNotifications),
      exhaustMap(({ payload }) =>
        this.notificationService.notificationListList(payload).pipe(
          map((notifications) =>
            NotificationActions.loadNotificationsSuccess({ notifications }),
          ),
          catchError((error: unknown) =>
            of(
              NotificationActions.loadNotificationsFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while loading notifications',
              }),
            ),
          ),
        ),
      ),
    );
  });

  markNotificationsAsWatched$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.markNotificationsAsWatched),
      concatMap(({ payload }) =>
        this.notificationService.notificationWatchedCreate(payload).pipe(
          map(() =>
            NotificationActions.markNotificationsAsWatchedSuccess({
              payload,
            }),
          ),
          catchError((error: unknown) =>
            of(
              NotificationActions.markNotificationsAsWatchedFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while marking notification as read',
              }),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {}
}
